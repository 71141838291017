import React from "react";
import { MeetingStatusEnum } from "../enums";
import { AzureCommunicationsService, AzureCommunicationsServiceIdentity, MeetingStatus, MsTeamsMeeting } from "../models";

export interface MeetingContextProps {
    meetingStatus : MeetingStatus;
    
    fetchMeetingStatus: (employeeId: string, meetingId: string) => Promise<void>;
    isFetchingMeetingStatus: boolean;
    
    onMarkRecordingConsent: (employeeId: string, meetingId: string) => Promise<string | undefined>;
    isMarkingRecordingConsent: boolean;

    azureCommunicationsService: AzureCommunicationsService;
    msTeamsMeeting: MsTeamsMeeting;
    azureCommunicationsServiceIdentity: AzureCommunicationsServiceIdentity;
    initializeLiveMeeting: (employeeId: string, meetingId: string) => Promise<void>;
}

export const defaultMeetingContext: MeetingContextProps = {
    meetingStatus: { status: MeetingStatusEnum.Unknown} as MeetingStatus,
    
    fetchMeetingStatus: _ => new Promise<void>(_ => null),
    isFetchingMeetingStatus: false,

    onMarkRecordingConsent: _ => new Promise<string | undefined>(_ => null),
    isMarkingRecordingConsent: false,

    azureCommunicationsService: {} as AzureCommunicationsService,
    msTeamsMeeting: {} as MsTeamsMeeting,
    azureCommunicationsServiceIdentity: {} as AzureCommunicationsServiceIdentity,
    initializeLiveMeeting: _ => new Promise<void>(_ => null)
};

export const MeetingContext = React.createContext<MeetingContextProps>(defaultMeetingContext);

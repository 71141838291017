import React, { useContext, useEffect, useState } from "react";
import { MetadataContext, LanguageContext } from "../contexts";
import pl from "@translations/pl";
import en from "@translations/en";
import ua from "@translations/ua";
import { ILanguage, ITranslation } from "../models/translations";


export const LanguageStore: React.FC = props => {
    
    const fallBackTranslation = pl;
    const supportedTranslations = [pl, en, ua];

    const [currentTranslation, setCurrentTranslation] = useState<ITranslation>(fallBackTranslation);
    const [enabledTranslations, setEnabledTranslations] = useState<ITranslation[]>([]);
    
    const metadataContext = useContext(MetadataContext);


    const translationByLangCode = (code: string) => {
        return supportedTranslations.find(l => l.language.languageCode === code);
    }

    const getAvailableLanguages = () : ILanguage[] => {
        return enabledTranslations.map(l => l.language);
    }

    const setLanguage = (langCode: string) => {
        const toChange = enabledTranslations.find(l => l.language.languageCode === langCode);
        if(toChange) {
            setCurrentTranslation(toChange);
        }
    }

    useEffect(() => {
        const enabledTranslations: ITranslation[] = [];
        
        let defaultTranslation = supportedTranslations.find(l => l.language.languageCode === metadataContext.metadata.internationalizationSettings.defaultLanguageCode);

        if(!defaultTranslation) {
            defaultTranslation = fallBackTranslation;
        }

        if (metadataContext.metadata.featureFlags.internationalizationEnabled) {
            enabledTranslations.push(defaultTranslation);

            metadataContext.metadata.internationalizationSettings.availableLanguageCodes.forEach(code => {
                const translation = translationByLangCode(code);
                if (translation && translation != defaultTranslation) {
                    enabledTranslations.push(translation);
                }
            });

            const browserLanguageCode = window.navigator.language;
            const translationByBrowser = enabledTranslations.find(t => t.language.browserLanguageIsoCodePrefixes.findIndex(c => c === browserLanguageCode) >= 0 || 
                t.language.browserLanguageIsoCodePrefixes.findIndex(c => browserLanguageCode.startsWith(`${c}-`)) >= 0);

            if (translationByBrowser) {
                defaultTranslation = translationByBrowser
            }
        }
        setEnabledTranslations(enabledTranslations);
        setCurrentTranslation(defaultTranslation);

    }, [metadataContext.metadata.internationalizationSettings]);

    

    return (
        <LanguageContext.Provider value={{
            setLanguage: setLanguage,
            availableLanguages: getAvailableLanguages,
            messages:  currentTranslation.messages,
            currentLanguage: currentTranslation.language
        }}>
            {props.children}
        </LanguageContext.Provider>
    );
};

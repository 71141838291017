import React from "react";
import { GlobalStore, EmployeesStore, MetadataStore, MeetingStore, QueryParamsStore } from "./";
import { LanguageStore } from "./LanguageStore";

export const Stores: React.FC = (props) => (
  <QueryParamsStore>
    <GlobalStore>
      <MetadataStore>
        <LanguageStore>
          <EmployeesStore>
            <MeetingStore>{props.children}</MeetingStore>
          </EmployeesStore>
        </LanguageStore>
      </MetadataStore>
    </GlobalStore>
  </QueryParamsStore>
);

import { ReactElement, useContext, useState } from "react";
import { IStackStyles, IStackTokens, Stack, Text } from "@fluentui/react";

import styles from "./availabilityCalendar.module.scss";
import { Employee, EmployeeAvailabilityDay, EmployeeAvailabilitySlot } from "../../models";
import AvailabilityDayCard from "../availability-day-card/AvailabilityDayCard";
import { EmployeeProfile } from "..";
import MeetingDataItem from "../../../meetingDetailsForm/MeetingDataItem";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../constants";
import { LanguageContext } from "../../contexts";


export interface AvailabilityCalendarProps {
  days: EmployeeAvailabilityDay[];
  onSlotSelected: (slot: EmployeeAvailabilitySlot) => void;
  employee: Employee;
}

const sectionStackTokens: IStackTokens = { childrenGap: 20 };

const stackStyles: IStackStyles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '20px'
  },
};

const AvailabilityCalendar: React.FC<AvailabilityCalendarProps> = (props) => {
  const navigation = useNavigate();
  const languageContext = useContext(LanguageContext);

  const renderCalendarDays = () => {
    const columns: ReactElement[] = [];
    let previousDay: Date | undefined = undefined;
    for (let i = 0; i < props.days.length; i++) {
      columns.push(
        <AvailabilityDayCard
          day={props.days[i]}
          onSlotSelected={props.onSlotSelected}
        />
      );
      previousDay = props.days[i].day;
    }

    return columns;
    // additionalClassName={styles.meetingDataItem}
  };

  const renderEmployeeProfile = () => {
    return <EmployeeProfile employee={props.employee} hidePresence />;
  };

  const onEmployeeChange = () => {
    navigation(routes.root);
  };

  return (
    <Stack horizontalAlign="center" className={styles.availabilityCalendar} tokens={sectionStackTokens}>
      <MeetingDataItem
        onActionButtonClick={onEmployeeChange}
        isActionButtonDisabled={false}
        meetingDataNode={renderEmployeeProfile}
      ></MeetingDataItem>

      {props.days && props.days.length > 0 ? (
        <>
          <Text className={styles.title} variant="xLarge">{languageContext.messages.availabilityCalendar.chooseMeetingTime}</Text>
          <Stack styles={stackStyles}>
            {renderCalendarDays()}
          </Stack>
        </>
      ) : (
        <Stack horizontalAlign="center" className={styles.availabilityCalendar}>
          <Text className={styles.title} variant="xLarge">
            {languageContext.messages.availabilityCalendar.chosenAgentHasNoSlotsAvailable}
          </Text>
          <Text className={styles.title} variant="xLarge">
            {languageContext.messages.availabilityCalendar.tryDifferentAgent}
          </Text>
        </Stack>
      )}
    </Stack>
  );
};

export default AvailabilityCalendar;

import React, { useContext, useEffect, useState } from "react";
import { AzureCommunicationTokenCredential, CommunicationUserIdentifier } from '@azure/communication-common';
import { CallAdapter, CallAdapterState, CallComposite, CallCompositeOptions, ChatAdapter, ChatComposite, ChatCompositeOptions, CompositeLocale, createAzureCommunicationCallAdapter, createAzureCommunicationChatAdapter, fromFlatCommunicationIdentifier } from "@azure/communication-react";
import { LanguageContext, MeetingContext, MetadataContext } from "../common/contexts";
import { useNavigate, useParams } from "react-router-dom";
import { routes, routeParams } from "../../constants";
import { PageLoader } from "./layout";
import { getEmployeeIdQueryParamValue, getMeetingIdQueryParamValue } from "../common/utils";
import { Stack, Panel, DefaultButton } from "@fluentui/react";
import styles from "./LiveMeetingPage.module.scss";


const mobileWidth = 800;

const commonCompositeOptions: CallCompositeOptions | ChatCompositeOptions = {
    callControls: {
        screenShareButton: false,
        participantsButton: false
    },
    topic: false
};

export const LiveMeetingPage: React.FC = () => {
    
    const params = useParams();
    const navigate = useNavigate();
    
    const metadataContext = useContext(MetadataContext);
    const meetingContext = useContext(MeetingContext);
	const languageContext = useContext(LanguageContext);

    const [fetchedMeetingData, setFetchedMeetingData] = useState(false);
    const [isInMeetingRoom, setIsInMeetingRoom] = useState(false);
    const [chatOpened, setChatOpened] = useState(false);
    const [employeeId, setEmployeeId]  = useState(getEmployeeIdQueryParamValue(params));
    const [meetingId, setMeetingId]  = useState(getMeetingIdQueryParamValue(params));

    const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileWidth);
    const [callAdapter, setCallAdapter] = useState<CallAdapter>();
    const [chatAdapter, setChatAdapter] = useState<ChatAdapter>();

    const locale = languageContext.messages.liveMeeting;

    useEffect(() => {
        const fetchMeetingData = async () => {
            await meetingContext.initializeLiveMeeting(employeeId, meetingId);
            setFetchedMeetingData(true);
        };

        if (metadataContext.metadata.featureFlags.msTeamsInterop) {
            fetchMeetingData();
            window.addEventListener("resize", onWindowResize);
        } else {
            const meetingPageUrl = routes.meeting.replace(routeParams.employeeId, employeeId).replace(routeParams.meetingId, meetingId);
            navigate(meetingPageUrl);
        }

        return () => {
            window.removeEventListener("resize", onWindowResize);
            callAdapter?.offStateChange(onCallAdapterStateChanged);
        };
    }, []);

    useEffect(() => {
        const initializeAdapters = async () => {

            const userId = fromFlatCommunicationIdentifier(meetingContext.azureCommunicationsServiceIdentity.id) as CommunicationUserIdentifier;
            const credential = new AzureCommunicationTokenCredential(meetingContext.azureCommunicationsServiceIdentity.accessToken);

            const initializeCallAdapterPromise = createAzureCommunicationCallAdapter({
                userId: userId,
                displayName: meetingContext.azureCommunicationsServiceIdentity.displayName,
                credential: credential,
                locator: { meetingLink: meetingContext.msTeamsMeeting.joinUrl }
            });

            const initializeChatAdapterPromise = createAzureCommunicationChatAdapter({
                endpoint: meetingContext.azureCommunicationsService.endpoint,
                userId: userId,
                displayName: meetingContext.azureCommunicationsServiceIdentity.displayName,
                credential: credential,
                threadId: meetingContext.msTeamsMeeting.chatId
            });

            const [callAdapter, chatAdapter] = await Promise.all([initializeCallAdapterPromise, initializeChatAdapterPromise]);
            callAdapter.onStateChange(onCallAdapterStateChanged);

            setCallAdapter(callAdapter);
            setChatAdapter(chatAdapter);
        };

        if (fetchedMeetingData) {
            initializeAdapters();
        }
    }, [fetchedMeetingData]);

    const onWindowResize = () => {
        if (window.innerWidth <= mobileWidth) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    const onCallAdapterStateChanged = (callAdapterState: CallAdapterState) => {
        const callState = callAdapterState?.call?.state;

        switch (callState) {
            case "Connected":
                setIsInMeetingRoom(true);
                break;

            case "Disconnecting":
            case "Disconnected":
                setIsInMeetingRoom(false);
                break;

          
            case "None":
            case undefined:
                setIsInMeetingRoom(false);
                break;
        }

    };

    if (fetchedMeetingData === false || callAdapter === undefined || chatAdapter === undefined) {
        return <PageLoader />;
    }

    if (isMobile) {
        return (
            <Stack className={styles.page}>
                {isInMeetingRoom && !chatOpened && <DefaultButton className={styles.chatButton} iconProps={{iconName: "Chat"}} onClick={() => setChatOpened(true)} />}
                <CallComposite adapter={callAdapter} locale={locale} options={commonCompositeOptions} formFactor="mobile" />
                <Panel isOpen={chatOpened} onDismiss={() => setChatOpened(false)}>
                    {isInMeetingRoom && (
                        <div className={styles.mobileChat}>
                            <ChatComposite adapter={chatAdapter} locale={locale} options={{...commonCompositeOptions, errorBar: false}} />
                        </div>
                    )}
                </Panel>
            </Stack>
        );
    }

    return (
        <Stack className={styles.page} horizontal>
            <Stack.Item grow disableShrink>
                <CallComposite adapter={callAdapter} locale={locale} options={commonCompositeOptions} />
            </Stack.Item>
            <Stack.Item shrink>
                {isInMeetingRoom && (
                    <ChatComposite adapter={chatAdapter} locale={locale} options={{...commonCompositeOptions, errorBar: false}} />
                )}
            </Stack.Item>
        </Stack>
    );
};

import { Dropdown, IDropdownOption, IRenderFunction, ISelectableOption, Stack } from "@fluentui/react";
import { useContext } from "react";
import { LanguageContext, MetadataContext } from "../../../common/contexts";
import styles from "./LanguageSwitch.module.scss";
import 'flag-icons/css/flag-icons.min.css';

export const LanguageSwitch: React.FC = props => {
    const languageContext = useContext(LanguageContext);
    const metadataContext = useContext(MetadataContext);

    const getOptions = () => {
        return languageContext.availableLanguages().map(l => { return { key: l.languageCode, text: l.languageName } });
    }

    const onRenderTitle = (props?: IDropdownOption[], defaultRender?: (props?: IDropdownOption[]) => JSX.Element | null): JSX.Element | null => {
        const value = props?.find(x => true);
        if (!value) {
            return null;
        }
        return renderItem(value.key, value.text);
    }

    const onRenderOption = (props?: ISelectableOption, defaultRender?: (props?: ISelectableOption) => JSX.Element | null): JSX.Element | null =>
    {
        if(!props) {
            return null;
        }
        return renderItem(props.key, props.text);
    };

    const renderItem = (key: string | number, text: string) => {
        return (
            <Stack horizontal tokens={{ childrenGap: 20 }} >
            <span className={`fi fi-${getCountrySymbolByLangCode(key)}`}></span>
              <span>{text}</span>
            </Stack>
          );
    }

    const getCountrySymbolByLangCode = (langCode: string | number) => {
        return languageContext.availableLanguages().find(l => l.languageCode === langCode)?.countrySymbol;
    }

    if (!metadataContext.metadata.featureFlags.internationalizationEnabled) {
        return null;
    }

    if (languageContext.availableLanguages().length <= 1) {
        return null;
    }

    return <Dropdown
        className={styles.languageSwitch}
        calloutProps={{calloutMaxHeight: 300}}
        selectedKey={languageContext.currentLanguage.languageCode}
        options={getOptions()}
        onChange={(_, option) => languageContext.setLanguage(option?.key as string)}
        onRenderOption={onRenderOption}
        onRenderTitle={onRenderTitle}
    />
};
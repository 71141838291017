import { useContext, useEffect, useState } from "react";
import BookMeeting from "../bookMeeting/BookMeeting";
import { EmployeesContext } from "../common/contexts";
import { PageLoader } from "./layout";

export const BookMeetingPage: React.FC = () => {

    const employeesContext = useContext(EmployeesContext);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchEmployees = async () => {
            employeesContext.resetState();
            await employeesContext.fetchEmployees();
            setLoading(false);
        };

        fetchEmployees();
    }, []);

	if (loading) {
        return <PageLoader />;
	}

    return (
        <BookMeeting employees={employeesContext.employees} loading={employeesContext.fetchingEmployees} />
    );
};

import React from "react";
import { IStackTokens, Stack } from "@fluentui/react";
import { LanguageContext, MetadataContext } from "../common/contexts";
import { Employee } from "../common/models";
import styles from "./availableEmployeeCard.module.scss";
import { Button, EmployeeProfile } from "../common/components";

export enum AvailableEmployeeCardActions {
	select,
	callNow,
	scheduleMeeting
};

export interface AvailableEmployeeCardProps {
	employee: Employee;
	possibleActions: AvailableEmployeeCardActions[],
	onSelect?: (employee: Employee) => void;
	onStartMeeting?: (employee: Employee) => void;
	onScheduleMeeting?: (employee: Employee) => void;
	hidePresence?: boolean;
}

const AvailableEmployeeCard: React.FC<AvailableEmployeeCardProps> = props => {

	const metadataContext = React.useContext(MetadataContext);
	const languageContext = React.useContext(LanguageContext);

	const sectionStackTokens: IStackTokens = { childrenGap: 20 };

	const onClickSelect = () => {
		if (props.possibleActions.some(action => action === AvailableEmployeeCardActions.select) && props.onSelect) {
			props.onSelect(props.employee);
		}
	}

	const onClickScheduleMeeting = () => {
		if (props.possibleActions.some(action => action === AvailableEmployeeCardActions.scheduleMeeting) && props.onScheduleMeeting) {
			props.onScheduleMeeting(props.employee);
		}
	}

	const tryRenderSelectButton = () => {
		if (props.possibleActions.some(action => action === AvailableEmployeeCardActions.select)) {
			return (
				<Button inverted onClick={onClickSelect} text={languageContext.messages.bookMeeting.select} />
			);
		}

		return null;
	};

	const tryRenderScheduleMeetingButton = () => {
		if (props.possibleActions.some(action => action === AvailableEmployeeCardActions.scheduleMeeting)) {
			const isDisabled = !(metadataContext.metadata.featureFlags.scheduledMeetingsEnabled);
			return (
				<Button disabled={isDisabled} onClick={onClickScheduleMeeting} text={languageContext.messages.bookMeeting.scheduleMeeting} iconName="Calendar" />
			);
		}

		return null;
	};

	return (
		<div className={styles.card}>
			<Stack tokens={sectionStackTokens}>
				<EmployeeProfile employee={props.employee} hidePresence={props.hidePresence} />
				{tryRenderSelectButton()}
				{tryRenderScheduleMeetingButton()}
			</Stack>
		</div>
	);
};

export default AvailableEmployeeCard;

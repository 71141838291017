import React, { useContext, useState } from "react";
import { GlobalContext, LanguageContext } from "../contexts";

export const GlobalStore: React.FC = props => {
    
    const [appLoading, setAppLoading] = useState<boolean>(true);
    const [appLoadingMessage, setAppLoadingMessage] = useState<string | undefined>();
    const [appError, setAppError] = useState<string>("Something went wrong...");

    const onSetAppLoading = (isLoading: boolean, loadingMessage?: string) => {
        setAppLoading(isLoading);
        setAppLoadingMessage(loadingMessage);
    };

    const onSetAppError = (errorMessage?: string) => {
        if (errorMessage) {
            setAppError(errorMessage);
        }
        //TODO: Redirect to /error
    };

    return (
        <GlobalContext.Provider value={{ 
            appLoading: appLoading,
            appLoadingMessage: appLoadingMessage,
            setAppLoading: onSetAppLoading,
            
            appError: appError,
            setAppError: onSetAppError
        }}>
            {props.children}
        </GlobalContext.Provider>
    );
};

export const constants = {
  defaultMeetingDurationInMinutes: 60,
  defaultMeetingIntervalInMinutes: 30,

  dateFormat: "dd.MM.yyyy",
  timeFormat: "HH:mm",

  minimalContrastRatio: 5,
  shorthandColorRegExp: /^#?([a-f\d])([a-f\d])([a-f\d])$/i,

  anyEmployeeId: "any"
};

export const validation = {
  // eslint-disable-next-line
  emailRegExp: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phoneNumberRegExp: /^(\(?\+?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(\s?)+$/,
  // eslint-disable-next-line
  cityNameRegExp: /^[^!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+$/,
};

export const routeParams = {
  employeeId: ":employeeId",
  meetingId: ":meetingId"
};

export const routes = {
  root: "/",
  error: "/error",
  meetingDetailsPage: `/employees/${routeParams.employeeId}/meetings/details`,
  meeting: `/employees/${routeParams.employeeId}/meetings/${routeParams.meetingId}`,
  meetingLive: `/employees/${routeParams.employeeId}/meetings/${routeParams.meetingId}/live`,
  employeeSchedule: `/employees/${routeParams.employeeId}/schedule`,
  scheduleMeetingStartingWithDay: `/meetings/scheduleOnDay`
};

import { useContext, useEffect, useState } from "react";
import { EmployeesContext } from "../common/contexts";
import { routeParams, routes } from "../../constants";
import AvailabilityCalendar from "../common/components/availability-calendar/AvailabilityCalendar";
import { Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { EmployeeAvailabilitySlot } from "../common/models";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { NotFoundPage } from ".";

export const AvailabilityCalendarPage: React.FC = () => {

	const employeesContext = useContext(EmployeesContext);
	const params = useParams();
	const navigation = useNavigate();

	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const initialize = async () => {
			if (employeesContext.alreadyFetchedEmployess === false) {
				await employeesContext.fetchEmployees();
			}
		};

		initialize();
	}, []);

	useEffect(() => {
		const initialize = async () => {
			const employeeId = params[routeParams.employeeId.replace(":", "")] ?? "";
			const employee = employeesContext.employees.find(employee => employee.id === employeeId);
			if (employee) {
				employeesContext.setSelectedEmployee(employee);
				await employeesContext.fetchAvailabilityDays(employeeId);
			}

			setLoading(false);
		};

		if (employeesContext.alreadyFetchedEmployess) {
			initialize();
		}
	}, [employeesContext.alreadyFetchedEmployess]);

	if (loading === false && employeesContext.alreadyFetchedEmployess && employeesContext.selectedEmployee === null) {
		return (
			<NotFoundPage />
		);
	}

	return (
		<>
			{loading || employeesContext.fetchingAvailabilityDays &&
				<Stack styles={{ root: { minHeight: '60vh' } }} verticalAlign="center">
					<Spinner size={SpinnerSize.large} />
				</Stack>
			}
			{!employeesContext.fetchingAvailabilityDays && employeesContext.selectedEmployee !== null && (
				<AvailabilityCalendar
					employee={employeesContext.selectedEmployee}
					days={employeesContext.availabilityDays}
					onSlotSelected={(slot: EmployeeAvailabilitySlot) => {
						employeesContext.setSelectedSlot(slot);
						navigation(routes.meetingDetailsPage.replace(routeParams.employeeId, employeesContext.selectedEmployee?.id ?? ""));
					}}
				/>
			)}
		</>
	);
};

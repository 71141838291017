import { useContext, useEffect, useState } from "react";
import JoinMeeting from "../joinMeeting/JoinMeeting";
import { MeetingContext, MetadataContext } from "../common/contexts";
import { useParams } from "react-router-dom";
import { routeParams } from "../../constants";

export interface MeetingPageProps {}

export const MeetingPage: React.FC<MeetingPageProps> = () => {
    
    const params = useParams();

    const meetingContext = useContext(MeetingContext);
    const metadataContext = useContext(MetadataContext);

    const [employeeId, setEmployeeId]  = useState<string>("");
    const [meetingId, setMeetingId]  = useState<string>("");

    useEffect(() => {
        setEmployeeId(params[routeParams.employeeId.replace(":", "")] ?? "");
        setMeetingId(params[routeParams.meetingId.replace(":", "")] ?? "");
    }, []);

    useEffect(() => {
        if (!employeeId || !meetingId) {
            return;
        }

        const fetchMeetingStatus = async () => {
            await meetingContext.fetchMeetingStatus(employeeId, meetingId);
        };
    
        fetchMeetingStatus();
    }, [employeeId, meetingId]);

    const onMarkRecordingConsent = async () : Promise<string | undefined> => {
        return await meetingContext.onMarkRecordingConsent(employeeId, meetingId);
    };

  return <JoinMeeting
        meetingStatus={meetingContext.meetingStatus}
        loading={meetingContext.isFetchingMeetingStatus}
        consents={metadataContext.metadata.consents}
        isMarkingRecordingConsetRequired={metadataContext.metadata.featureFlags.callCenterEnabled}
        isMarkingRecordingConsentInProgress={meetingContext.isMarkingRecordingConsent}
        onMarkRecordingConsent={onMarkRecordingConsent}
      />;
};

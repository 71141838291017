import api from "../generated";
import { AzureCommunicationsService, AzureCommunicationsServiceIdentity, MsTeamsMeeting } from "../../models";

export interface InitializeLiveMeetingResult {
    azureCommunicationsService: AzureCommunicationsService;
    msTeamsMeeting: MsTeamsMeeting;
    azureCommunicationsServiceIdentity: AzureCommunicationsServiceIdentity;
};

export interface MeetingsService {
    initializeLiveMeeting: (employeeId: string, meetingId: string) => Promise<InitializeLiveMeetingResult>;
};

export const meetingsService: MeetingsService = {
    initializeLiveMeeting: async (employeeId, meetingId) => {
        const apiResult = await api.initializeLiveMeeting(employeeId, meetingId);
        const model: InitializeLiveMeetingResult = {
            azureCommunicationsService: {
                endpoint: apiResult?.communicationsService?.endpoint ?? ""
            },
            msTeamsMeeting: {
                joinUrl: apiResult?.meeting?.joinUrl ?? "",
                chatId: apiResult?.meeting?.chatThreadId ?? ""
            },
            azureCommunicationsServiceIdentity: {
                id: apiResult?.user?.id ?? "",
                displayName: apiResult?.user?.displayName ?? "",
                accessToken: apiResult?.user?.accessToken ?? ""
            }
        };

        return model;
    }
};

import { DefaultButton, Icon, IIconStyles, Separator, Stack, Text } from "@fluentui/react";
import { Employee, EmployeeAvailabilityDay, EmployeeAvailabilitySlot } from "../../models";
import styles from "./availabilityDayCard.module.scss";
import * as dateUtils  from "../../../../utils/dateUtils";
import { Button } from "../button/Button";
import { useContext } from "react";
import { LanguageContext } from "../../contexts";




export interface AvailabilityDayCardProps {
  day: EmployeeAvailabilityDay;
  onSlotSelected: (slot: EmployeeAvailabilitySlot) => void;
}

const AvailabilityDayCard: React.FC<AvailabilityDayCardProps> = (props) => {

  const languageContext = useContext(LanguageContext);
  const iconStyles: IIconStyles = {
    root: {
      fontSize: '24px',
      height: '24px',
      width: '24px',
    },
  };


const getSlotDescription  = (slot: EmployeeAvailabilitySlot) => {
  return `${dateUtils.toLocalizedHourMinute(languageContext.currentLanguage.languageCode, slot.startAt)} - ${dateUtils.toLocalizedHourMinute(languageContext.currentLanguage.languageCode, slot.endAt)}`
}

return <div className={styles.container}>
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack verticalAlign="center" horizontalAlign="center">
            <Text variant="xLarge"  >{dateUtils.toLocalizedDate(languageContext.currentLanguage.languageCode, props.day.day)}</Text>
            <Text variant="mediumPlus">{dateUtils.toLocalizedWeekday(languageContext.currentLanguage.languageCode, props.day.day)}</Text>
          </Stack>
          <Separator>
            <Icon iconName="Clock" styles={iconStyles} />
          </Separator>
          <Stack tokens={{ childrenGap: 12 }} wrap>
            {props.day.slots.map(slot => {
              return <Button onClick={() => { props.onSlotSelected(slot); }} text={getSlotDescription(slot)} additionalClassName={styles.slotButton} additionalTextClassName={styles.slot} />
            })}
          </Stack>
        </Stack>
        </div>
};

export default AvailabilityDayCard;
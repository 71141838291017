import React, { useContext, useEffect, useState } from "react";
import { Icon, IStackTokens, Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import styles from "./joinMeeting.module.scss";
import { Button, CheckboxWithLabel, Title } from "../common/components";
import { Consent, MeetingStatus } from "../common/models";
import { MeetingStatusEnum } from "../common/enums";
import { ConsentTypeEnum } from "../common/enums/ConsentTypeEnum";
import { useNavigate, useParams } from "react-router-dom";
import { routeParams, routes } from "../../constants/index";
import { MetadataContext, LanguageContext } from "../common/contexts";

export interface JoinMeetingProps {
	meetingStatus: MeetingStatus;
	loading: boolean;
	consents: Consent[];
	isMarkingRecordingConsetRequired?: boolean;
	isMarkingRecordingConsentInProgress: boolean;
	onMarkRecordingConsent: () => Promise<string | undefined>;
}

const JoinMeeting: React.FC<JoinMeetingProps> = (props) => {

	const navigation = useNavigate();
	const params = useParams();
	const metadataContext = useContext(MetadataContext);

	const [msTeamsJoinMeetingUrl, setMsTeamsJoinMeetingUrl] = useState<string | undefined>("")
	const languageContext = useContext(LanguageContext);
	const [isRecordingConsentChecked, setIsRecordingConsentChecked] = useState(false);

	useEffect(() => {
		if (!msTeamsJoinMeetingUrl) {
			setMsTeamsJoinMeetingUrl(props.meetingStatus.msTeamsJoinMeetingUrl);
		}
	}, [props.loading]);

	const stackTokens: IStackTokens = { childrenGap: 20 };
	const stackTokensProgressVsDetails: IStackTokens = { childrenGap: 60 };

	const getGraphicStatus = (): JSX.Element => {
		if (props.meetingStatus.status === MeetingStatusEnum.Creating) {
			return <Spinner size={SpinnerSize.large}></Spinner>;
		}

		let iconName = "ChromeClose";
		switch (props.meetingStatus.status) {
			case MeetingStatusEnum.ReadyToStart:
				iconName = "CheckMark";
				break;
			case MeetingStatusEnum.InProgress:
				iconName = "CheckMark";
				break;
			default:
				break;
		}

		return <Icon className={styles.statusIcon} iconName={iconName} />;
	};

  const getTitle = (): string => {
    switch (props.meetingStatus.status) {
      case MeetingStatusEnum.Creating:
        return languageContext.messages.meetingPage.meetingStatus.creating;
      case MeetingStatusEnum.Ended:
        return languageContext.messages.meetingPage.meetingStatus.ended;
      case MeetingStatusEnum.InProgress:
        return languageContext.messages.meetingPage.meetingStatus.inProgress;
      case MeetingStatusEnum.ReadyToStart:
        return languageContext.messages.meetingPage.meetingStatus.readyToStart;
      case MeetingStatusEnum.Canceled:
        return languageContext.messages.meetingPage.meetingStatus.canceled;
      default:
        return languageContext.messages.meetingPage.meetingStatus.unknown;
    }
  };

	const detailsMessage = (message: string): JSX.Element => {
		return (
			<Text variant="xLarge" className={styles.detailedMessage}>
				{message}
			</Text>
		);
	};

	const getScheduleMessage = (): JSX.Element | null => {
		if (
			props.meetingStatus.status !== MeetingStatusEnum.Creating &&
			props.meetingStatus.status !== MeetingStatusEnum.InProgress &&
			props.meetingStatus.status !== MeetingStatusEnum.ReadyToStart
		) {
			return null;
		}

    if (props.meetingStatus.officialMeetingTime?.start) {
      return detailsMessage(
        languageContext.messages.meetingPage.meetingScheduledTo(
          props.meetingStatus.officialMeetingTime!.start!
        )
      );
    }

		return null;
	};

	const getJoinFromMessage = (): JSX.Element | null => {
		if (
			props.meetingStatus.status !== MeetingStatusEnum.InProgress &&
			props.meetingStatus.status !== MeetingStatusEnum.ReadyToStart
		) {
			return null;
		}

    if (
      props.meetingStatus.allowJoiningEarlier &&
      props.meetingStatus.allowedJoinTime?.start
    ) {
      return detailsMessage(
        languageContext.messages.meetingPage.joinMeetingAllowedFrom(
          props.meetingStatus.allowedJoinTime!.start!
        )
      );
    }
    return null;
  };

  const getRecordingConsent = () => {
    const allRecordingConsents = props.consents.filter((c) => c.consentType === ConsentTypeEnum.Recording);
    const consentByLang = allRecordingConsents.find((c) => c.languageCode === languageContext.currentLanguage.languageCode);
    if (consentByLang) {
      return consentByLang.content;
    }
    return allRecordingConsents.find((c) => !c.languageCode)?.content;
  };

	const renderOpenMeetingButtonWithConsent = () => {
		if (
			props.meetingStatus.status !== MeetingStatusEnum.ReadyToStart &&
			props.meetingStatus.status !== MeetingStatusEnum.InProgress
		) {
			return null;
		}

		if (!props.meetingStatus.allowedJoinTime?.start || props.meetingStatus.allowedJoinTime.start > new Date()) {
			return null;
		}

		if (props.loading) {
			return null;
		}

		return (
			<Stack className={styles.consentWrapper}
				tokens={{ childrenGap: 20 }}
			>
				{props.isMarkingRecordingConsetRequired &&
					<CheckboxWithLabel
						additionalClassName={styles.consentLabel}
						onCheckedChanged={() => {
							setIsRecordingConsentChecked(!isRecordingConsentChecked);
						}}
						disabled={getRecordingConsent() === undefined}
						checked={isRecordingConsentChecked}
						label={getRecordingConsent() ?? ""}
						labelAsRawHtml={true}
					/>
				}

        <Stack.Item
          align="center"
          styles={{ root: { width: "380px", textAlign: "center" } }}
        >
          <Button
            onClick={async () => {
				let urlToOpen = msTeamsJoinMeetingUrl;
				if (props.isMarkingRecordingConsetRequired && !msTeamsJoinMeetingUrl) {
					urlToOpen = await props.onMarkRecordingConsent();
					setMsTeamsJoinMeetingUrl(urlToOpen);
				}

				if (metadataContext.metadata.featureFlags.msTeamsInterop) {
					const liveMeetingUrl = routes.meetingLive
						.replace(routeParams.employeeId, params[routeParams.employeeId.replace(":", "")] ?? "")
						.replace(routeParams.meetingId, params[routeParams.meetingId.replace(":", "")] ?? "");

					navigation(liveMeetingUrl);
				} else {
					window.open(urlToOpen, "_blank");
				}
            }}
            disabled={props.isMarkingRecordingConsentInProgress || (props.isMarkingRecordingConsetRequired && !isRecordingConsentChecked)}
            inverted
			text={languageContext.messages.meetingPage.openMeeting}
            iconName={props.isMarkingRecordingConsentInProgress ? "ProgressRingDots" : "VideoSolid"}
          />
        </Stack.Item>
      </Stack>
    );
  };

	const renderLoader = () => {
		return <Spinner size={SpinnerSize.large} />;
	};

	const renderStatus = () => {
		return (
			<Stack horizontal>
				{getGraphicStatus()}
				<Stack>
					<Title additionalClassName={styles.textSecondaryColor} content={getTitle()} />
					{getScheduleMessage()}
					{getJoinFromMessage()}
				</Stack>
			</Stack>
		);
	};

  return (
    <Stack
      className={styles.container}
      horizontalAlign="center"
      verticalAlign="center"
      tokens={stackTokens}
    >
      <Stack horizontal wrap>
        <Title additionalClassName={styles.title} large content={languageContext.messages.meetingDetailsForm.headerPart1}></Title>
        <Title additionalClassName={styles.titleAccent} large content={languageContext.messages.meetingDetailsForm.headerPart2}></Title>
      </Stack>

			<Stack
				wrap
				horizontalAlign="center"
				className={styles.statusCard}
				tokens={stackTokensProgressVsDetails}
			>
				{props.loading ? renderLoader() : renderStatus()}

        {renderOpenMeetingButtonWithConsent()}
      </Stack>
      {(props.meetingStatus.status === MeetingStatusEnum.Ended || props.meetingStatus.status === MeetingStatusEnum.Canceled)
        && <Button
            onClick={async () => {
              navigation(routes.root);
            }}
            inverted
            text={languageContext.messages.meetingPage.scheduleAnotherMeeting}
          />}
    </Stack>
  );
};

export default JoinMeeting;

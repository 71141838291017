import { CompositeLocale } from "@azure/communication-react";

export const liveMeetingPl : CompositeLocale = {
    strings: {
        call: {
            configurationPageTitle: "Rozpocznij spotkanie",
            configurationPageCallDetails: "Wybierz urządzenia",
            startCallButtonLabel: "Dołącz do spotkania",
            rejoinCallButtonLabel: "Dołącz ponownie do spotkania",
            defaultPlaceHolder: "-",
            cameraLabel: "Kamera",
            soundLabel: "Mikrofon i dźwięk",
            cameraPermissionDenied: "Nie wyrażono zgody na użycie kamery",
            cameraTurnedOff: "Kamera wyłączona",
            microphonePermissionDenied: "Nie wyrażono zgody na użycie mikrofonu",
            failedToJoinTeamsMeetingReasonAccessDeniedTitle: "Wystąpił błąd",
            failedToJoinTeamsMeetingReasonAccessDeniedMoreDetails: "Wystąpił błąd podczas dołączania do spotkania Microsoft Teams",
            failedToJoinCallDueToNoNetworkTitle: "Wystąpił błąd",
            failedToJoinCallDueToNoNetworkMoreDetails: "Wystąpił błąd podczas dołączania do spotkania",
            leftCallTitle: "Opuszczono spotkanie",
            leftCallMoreDetails: "Jeśli nie było to celowe, możesz ponownie dołączyć do spotkania",
            removedFromCallTitle: "Rozłączono spotkanie",
            removedFromCallMoreDetails: "Spotkanie zostało rozłączone",
            lobbyScreenConnectingToCallTitle: "Dołączanie do spotkania",
            lobbyScreenConnectingToCallMoreDetails: "Trwa dołączanie do spotkania",
            lobbyScreenWaitingToBeAdmittedTitle: "Oczekiwanie na wpuszczenie do spotkania",
            lobbyScreenWaitingToBeAdmittedMoreDetails: "Znajdujesz sie w poczekalni, trwa oczekiwanie na wpuszczenie przez organizatora spotkania",
            mutedMessage: "Mikrofon jest wyciszony",
            privacyPolicy: "zapoznaj się z polityką prywatności",
            learnMore: "Dowiedz się więcej",
            complianceBannerNowOnlyRecording: "Spotkanie jest nagrywane",
            complianceBannerNowOnlyTranscription: "Transkrypt spotkania jest nagrywany",
            complianceBannerRecordingAndTranscriptionSaved: "Zapisano wideo oraz transkrypt spotkania",
            complianceBannerRecordingAndTranscriptionStarted: "Rozpoczęto nagrywanie wideo oraz transkryptu spotkania",
            complianceBannerRecordingAndTranscriptionStopped: "Zatrzymano nagrywanie wideo oraz transkryptu spotkania",
            complianceBannerRecordingSaving: "Trwa zapisywanie nagrania spotkania:",
            complianceBannerRecordingStarted: "Rozpoczęto nagrywanie spotkania:",
            complianceBannerRecordingStopped: "Zatrzymano nagrywanie spotkania:",
            complianceBannerTranscriptionConsent: "spotkanie jest nagrywane z powodów prawnych,",
            complianceBannerTranscriptionSaving: "Zapisywanie transkryptu spotkania",
            complianceBannerTranscriptionStarted: "Rozpoczęto nagrywanie transkryptu spotkania",
            complianceBannerTranscriptionStopped: "Zatrzymano nagrywanie transkryptu spotkania",
            close: "Zamknij",
            networkReconnectTitle: "Dołącz ponownie",
            networkReconnectMoreDetails: "Dołącz ponownie do spotkania",
            microphoneToggleInLobbyNotAllowed: "Nie można włączyć mikrofonu w poczekalni"
        },
        chat: {
            chatListHeader: "chatListHeader"
        }
    },
    component: {
        strings: {
            messageThread: {
                sunday: "Niedziela",
                monday: "Poniedziałek",
                tuesday: "Wtorek",
                wednesday: "Środa",
                thursday: "Czwartek",
                friday: "Piątek",
                saturday: "Sobota",
                yesterday: "Wczoraj",
                participantJoined: "dołączył/ła do spotkania",
                participantLeft: "opuścił/opuściła spotkanie",
                editedTag: "Zmodyfikowano",
                editMessage: "Zmodyfikuj",
                removeMessage: "Usuń",
                liveAuthorIntro: "liveAuthorIntro",
                editBoxTextLimit: "Przekroczono maksymalny limit znaków",
                editBoxPlaceholderText: "Wpisz treść wiadomości",
                newMessagesIndicator: "Nowa",
                noDisplayNameSub: "Anonim",
                editBoxCancelButton: "Anuluj",
                editBoxSubmitButton: "Zapisz"
            },
            participantItem: {
                isMeText: "Ja",
                menuTitle: "Opcje",
                removeButtonLabel: "Wyrzuć",
                sharingIconLabel: "Udostępnij",
                mutedIconLabel: "Opcje"
            },
            cameraButton: {
                onLabel: "Kamera włączona",
                offLabel: "Kamera wyłączona",
                tooltipDisabledContent: "Brak możliości użycia kamery",
                tooltipOnContent: "Wyłącz kamerę",
                tooltipOffContent: "Włącz kamerę",
                tooltipVideoLoadingContent: "Trwa włączanie kamery"
            },
            microphoneButton: {
                onLabel: "Mikrofon włączony",
                offLabel: "Mikrofon wyłączony",
                tooltipDisabledContent: "Brak możliwości użycia mikrofonu",
                tooltipOnContent: "Wyłącz mikrofon", 
                tooltipOffContent: "Włącz mikrofon"
            },
            endCallButton: {
                label: "Rozłącz",
                tooltipContent: "Zakończ rozmowę"
            },
            devicesButton: {
                label: "Urządzenia",
                tooltipContent: "Wybierz urządzenia",
                cameraMenuTitle: "Kamera",
                microphoneMenuTitle: "Mikrofon",
                speakerMenuTitle: "Dźwięk",
                cameraMenuTooltip: "Wybierz kamerę",
                microphoneMenuTooltip: "Wybierz mikrofon",
                speakerMenuTooltip: "Wybierz źródło dźwięku"
            },
            participantsButton: {
                label: "Uczestnicy",
                tooltipContent: "Obecni uczestnicy na spotkaniu",
                menuHeader: "Uczestnicy spotkania",
                participantsListButtonLabel: "Uczestnicy",
                copyInviteLinkButtonLabel: "Kopiuj link z zaproszeniem do spotkania",
                muteAllButtonLabel: "Wycisz wszystkich"
            },
            screenShareButton: {
                onLabel: "Prezentujesz swój ekran",
                offLabel: "Zaprezentuj swój ekran",
                tooltipDisabledContent: "Brak możliwości prezentowania twojego ekranu",
                tooltipOnContent: "Zakończ prezentację swojego ekranu",
                tooltipOffContent: "Rozpocznij prezentację swojego ekranu"
            },
            typingIndicator: {
                singleUser: "pisze...",
                multipleUsers: "piszą...",
                multipleUsersAbbreviateOne: "piszą...",
                multipleUsersAbbreviateMany: "piszą...",
                delimiter: ", "
            },
            sendBox: {
                placeholderText: "Wpisz treść wiadomości",
                textTooLong: "Przekroczono maksymalną ilość znaków",
                sendButtonAriaLabel: "Wyślij"
            },
            messageStatusIndicator: {
                deliveredAriaLabel: "Wysłana",
                deliveredTooltipText: "Wysłana",
                seenAriaLabel: "Odczytana",
                seenTooltipText: "Odczytana",
                sendingAriaLabel: "Wysyłana",
                sendingTooltipText: "Wysyłana",
                failedToSendAriaLabel: "Wystąpił problem podczas wysyłania wiadomości",
                failedToSendTooltipText: "Wystąpił problem podczas wysyłania wiadomości",
            },
            errorBar: {
                unableToReachChatService: "Wystąpił problem podczas próby pobrania czatu",
                accessDenied: "Odmowa dostępu",
                userNotInChatThread: "Nie masz już dostępu do tego czatu",
                sendMessageNotInChatThread: "Wystąpił błąd podczas próby wysłania wiadomości",
                sendMessageGeneric: "Wystąpił błąd podczas próby wysłania wiadomości",
                startVideoGeneric: "Wystąpił błąd podczas próby włączenia kamery",
                stopVideoGeneric: "Wystąpił błąd podczas próby wyłączenia kamery",
                muteGeneric: "Wystąpił błąd podczas próby wyciszenia mikrofonu",
                unmuteGeneric: "Wystąpił błąd podczas próby włączenia mikrofonu",
                startScreenShareGeneric: "Wystąpił błąd podczas próby rozpoczęcia prezentacji ekranu",
                stopScreenShareGeneric: "Wystąpił błąd podczas próby zakończenia prezentacji ekranu",
                callNetworkQualityLow: "Słaba jakość łącza",
                callNoSpeakerFound: "Wystąpił błąd podczas próby zlokalizowania urządzeń dźwięku",
                callNoMicrophoneFound: "Wystąpił błąd podczas próby zlokalizowania mikrofonu",
                callMicrophoneAccessDenied: "Odmowa dostępu do mikrofonu",
                callMicrophoneMutedBySystem: "Mikrofon został wyciszony systemowo",
                callMacOsMicrophoneAccessDenied: "Odmowa dostępu do mikrofonu",
                callLocalVideoFreeze: "Obraz zamrożony z powodu słabej jakości łącza",
                callCameraAccessDenied: "Odmowa dostępu do kamery",
                callCameraAlreadyInUse: "Kamera jest już w użyciu",
                callMacOsCameraAccessDenied: "Odmowa dostępu do kamery",
                callMacOsScreenShareAccessDenied: "Odmowa dostępu do prezentacji ekranu"
            },
            videoGallery: {
                screenIsBeingSharedMessage: "Prezentujesz swój ekran",
                screenShareLoadingMessage: "Trwa uruchamianie prezentacji ekranu",
                localVideoLabel: "Ja"
            }
        }
    }
};
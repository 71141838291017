import { AppLogo } from "./logo/AppLogo";
import { Stack } from "@fluentui/react";
import styles from "./AppHeader.module.scss";
import { LanguageSwitch } from "../language-switch/LanguageSwitch";

export const AppHeader: React.FC = () => (
	<Stack className={styles.appHeader} horizontal horizontalAlign="space-between" verticalAlign="center">
		<AppLogo />
		<LanguageSwitch />
	</Stack>
);

import * as _ from "lodash";
import * as parent from "./en";
import * as dateUtils from "../../../utils/dateUtils";

const nbsp: string = "\u00A0";
const ua = _.cloneDeep(parent.default);

ua.language = {
  languageCode: "ua",
  languageName: "Ukrainian",
  browserLanguageIsoCodePrefixes: ["ua"],
  countrySymbol: "ua",
};

ua.messages.meetingPage = {
  headerPart1: `Ваша безкоштовна${nbsp}`,
  headerPart2: `онлайн-порада`,
  meetingStatus: {
    unknown: "У нас немає інформації про цю зустріч",
    creating: "Готуємо вашу зустріч...",
    readyToStart: "Ваша зустріч готова",
    inProgress: "Ваша зустріч готова",
    ended: "Ваша зустріч закінчилася",
    canceled: "Вашу зустріч скасовано",
  },
  meetingScheduledTo: (date: Date) =>
    `Ваша зустріч запланована на ${dateUtils.getTeamsTimeFormat(
      date
    )} ${dateUtils.getTeamsDateFormat(date)}`,
  joinMeetingAllowedFrom: (date: Date) => {
    return `Ви можете приєднатися до нього з ${dateUtils.getTeamsTimeFormat(
      date
    )}`;
  },
  openMeeting: "Відкрийте зустріч",
  scheduleAnotherMeeting: "Призначте іншу зустріч",
};

ua.messages.common = {
  defaultAppErrorMessage: "Щось пішло не так...",
  save: "Зберегти",
  ok: "OK",
  pageNotFound: "Сторінку не знайдено",
};

ua.messages.bookMeeting = {
  header: "Знайдіть нову страховку з ecom Digital Agents",
  subheader1: `Візьміть безкоштовно${nbsp}`,
  subheader2: `онлайн поради`,
  callCenterNotEnabled:
    "Колл-центр наразі недоступний",
  noCallCenterEmployees: "На даний момент немає агента, який би вам допоміг",
  personaSecondaryText: "Digital Agent",
  startOnlineMeeting: "Почніть онлайн-зустріч",
  scheduleMeeting: "Призначте зустріч",
  select: "Виберіть",
  selectSchedulingDay: "День зустрічі",
  selectSchedulingSlot: "Час зустрічі",
  noSlotsAvailable: "Немає доступних слотів",
  chooseMeetingDate: "Виберіть дату зустрічі",
};

ua.messages.meetingDetailsForm = {
  headerPart1: `ваша безкоштовна${nbsp}`,
  headerPart2: `онлайн-порада`,
  meetNow: "Якнайшвидше",
  meetingDataItem: {
    change: "змінити",
  },
  dayOfTheWeek: (date: Date) => `${dateUtils.toLocalizedWeekday("ua", date)},`,
  startMeetingHour: (date: Date) =>
    `at ${dateUtils.toLocalizedHourMinute("ua", date)}`,
  firstName: "Ім'я",
  lastName: "Прізвище",
  email: "E-mail",
  phoneNumber: "Номер телефону",
  regulationsConsent:
    "Я приймаю умови надання безкоштовної онлайн-консультації та правила обробки персональних даних",
  meetingCreation: {
    creatingMeetingIsPending: "Створення зустрічі триває...",
    creatingMeetingIsSuccess: "Зустріч зарезервована",
    creatingMeetingIsConflict: "Вибраний агент зараз зайнятий",
    creatingMeetingIsError: "Під час створення зустрічі сталася помилка",
    onSuccessSection1: "Ми також надіслали підтвердження бронювання на вашу електронну адресу.",
    onSuccessSection2: "Побачимося!",
    onConflictSection1: "Вибраний агент зараз недоступний або має іншу зустріч.",
    onConflictSection2: "Виберіть іншого агента або повторіть спробу.",
    onErrorSection1: "Під час створення зустрічі сталася помилка",
    onErrorSection2: "Виберіть іншого агента або повторіть спробу.",
    openMeetingPage: "Відкрийте сторінку зустрічі",
  },
  createMeeting: "Призначте зустріч",
  validation: {
    wrongEmail: "Вказана електронна адреса недійсна",
    wrongPhoneNumber: "Наданий номер телефону недійсний",
  },
  backToMain: "Повернутися на домашню сторінку",
};

export default ua;

import { useContext } from "react";
import { LanguageContext } from "../../../common/contexts"
import styles from "./AppFooter.module.scss";

export const AppFooter: React.FC = () => {
	
	const languageContext = useContext(LanguageContext);

	return <div className={styles.appFooter}>
		<a href={languageContext.messages.footer.regulationsLink}>{languageContext.messages.footer.regulations}</a>
	</div>
};

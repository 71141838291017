import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { employeeService, meetingsService } from "../api/services";
import { defaultMeetingContext, GlobalContext, MeetingContext } from "../contexts";
import { AzureCommunicationsService, AzureCommunicationsServiceIdentity, MeetingStatus, MsTeamsMeeting } from "../models";
import { routes } from "../../../constants/index";

export const MeetingStore: React.FC = (props) => {
	const globalContext = useContext(GlobalContext);
	const navigation = useNavigate();

	const [meetingStatus, setMeetingStatus] = useState<MeetingStatus>({} as MeetingStatus);
	const [isFetchingMeetingStatus, setIsFetchingMeetingStatus] = useState(false);
	const [isMarkingRecordingConsent, setIsMarkingRecordingConsent] = useState(false);
	
	const [azureCommunicationsService, setAzureCommunicationsService] = useState<AzureCommunicationsService>(defaultMeetingContext.azureCommunicationsService);
	const [msTeamsMeeting, setMsTeamsMeeting] = useState<MsTeamsMeeting>(defaultMeetingContext.msTeamsMeeting);
	const [azureCommunicationsServiceIdentity, setAzureCommunicationsServiceIdentity] = useState<AzureCommunicationsServiceIdentity>(defaultMeetingContext.azureCommunicationsServiceIdentity);

	const onFetchMeetingStatus = async (employeeId: string, meetingId: string) => {
		try {
			setIsFetchingMeetingStatus(true);
			const meetStatus = await employeeService.getMeetingStatus(
				employeeId,
				meetingId
			);
			setMeetingStatus(meetStatus);
		} catch (error) {
			globalContext.setAppError();
			navigation(routes.error);
		} finally {
			setIsFetchingMeetingStatus(false);
		}
	};

	const onMarkRecordingConsent = async (employeeId: string, meetingId: string) => {
		try {
			setIsMarkingRecordingConsent(true);
			const url = await employeeService.markRecordingConsent(
				employeeId,
				meetingId
			);
			return url;
		} catch (error) {
			globalContext.setAppError();
			navigation(routes.error);
		} finally {
			setIsMarkingRecordingConsent(false);
		}
	};

	const onInitializeLiveMeeting = async (employeeId: string, meetingId: string) => { 
		const model = await meetingsService.initializeLiveMeeting(employeeId, meetingId);
		setAzureCommunicationsService({
			endpoint: model.azureCommunicationsService.endpoint
		});
		setMsTeamsMeeting({
			joinUrl: model.msTeamsMeeting.joinUrl,
			chatId: model.msTeamsMeeting.chatId
		});
		setAzureCommunicationsServiceIdentity({
			id: model.azureCommunicationsServiceIdentity.id,
			displayName: model.azureCommunicationsServiceIdentity.displayName,
			accessToken: model.azureCommunicationsServiceIdentity.accessToken
		});
	};

	return (
		<MeetingContext.Provider
			value={{
				meetingStatus: meetingStatus,
				fetchMeetingStatus: onFetchMeetingStatus,
				isFetchingMeetingStatus: isFetchingMeetingStatus,
				onMarkRecordingConsent: onMarkRecordingConsent,
				isMarkingRecordingConsent: isMarkingRecordingConsent,
				azureCommunicationsService: azureCommunicationsService,
				msTeamsMeeting: msTeamsMeeting,
				azureCommunicationsServiceIdentity: azureCommunicationsServiceIdentity,
				initializeLiveMeeting: onInitializeLiveMeeting
			}}
		>
			{props.children}
		</MeetingContext.Provider>
	);
};

import { Stack } from "@fluentui/react";
import { useContext } from "react";
import { QueryParamsContext } from "../../../common/contexts";
import { LanguageSwitch } from "../language-switch/LanguageSwitch";
import styles from "./AppContents.module.scss";

export const AppContents: React.FC = props => {

    const qParamsContext = useContext(QueryParamsContext);

    return <Stack>
        {qParamsContext.isEmbedded &&
            <Stack.Item align="end">
                <LanguageSwitch />
            </Stack.Item>}
        <div className={styles.appContents}>
            {props.children}
        </div>
    </Stack>
}

import format from "date-fns/format";
import { constants } from "../constants";
import {
  getDate,
  getMinutes,
  setMinutes,
  addMinutes,
  startOfHour,
  startOfDay,
} from "date-fns";

const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const dayOptions: Intl.DateTimeFormatOptions = {
  weekday: "long",
};

const hourMinute: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit",
};

export function toLocalizedDate(locales: string, date: Date) {
    return date.toLocaleDateString(locales, dateOptions);
}

export function toLocalizedWeekday(locales: string, date: Date) {
    return date.toLocaleDateString(locales, dayOptions);
}

export function toLocalizedHourMinute(locales: string, date: Date) {
    return date.toLocaleTimeString(locales, hourMinute);
}

export function getTeamsDateFormat(
  date: Date,
  dateFormat: string = constants.dateFormat
) {
  return format(date, dateFormat);
}

export function getTeamsTimeFormat(
  date: Date,
  timeFormat: string = constants.timeFormat
) {
  return format(date, timeFormat);
}

export function getDifferenceInHours(dateLeft: Date, dateRight: Date) {
  return dateLeft.getHours() - dateRight.getHours();
}

export function getDifferenceInMinutes(dateLeft: Date, dateRight: Date) {
  const diffInHours = getDifferenceInHours(dateLeft, dateRight);

  return diffInHours * 60 + dateLeft.getMinutes() - dateRight.getMinutes();
}

export const calculateMinTime = (
  date: Date,
  meetingIntervalInMinutes: number = constants.defaultMeetingIntervalInMinutes
) => {
  if (
    date.getFullYear() !== new Date().getFullYear() ||
    date.getMonth() !== new Date().getMonth() ||
    getDate(date) !== getDate(new Date())
  ) {
    return startOfDay(date);
  }

  const minutes = getMinutes(date);
  const fullTimeIntervals = Math.ceil(minutes / meetingIntervalInMinutes);
  const endingMinutes = fullTimeIntervals * meetingIntervalInMinutes;
  return setMinutes(startOfHour(date), endingMinutes);
};

export const getInitialEndingHour = (
  date: Date,
  meetingDurationInMinutes = constants.defaultMeetingDurationInMinutes,
  meetingIntervalInMinutes: number = constants.defaultMeetingIntervalInMinutes
) => {
  return addMinutes(
    calculateMinTime(date, meetingIntervalInMinutes),
    meetingDurationInMinutes
  );
};

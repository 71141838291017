import { Stack, Text } from "@fluentui/react";
import { useContext } from "react";
import { LanguageContext } from "../../common/contexts";
import styles from "./GlobalNotFound.module.scss";

const GlobalNotFound: React.FC = () => {

    const languageContext = useContext(LanguageContext);

    return <Stack verticalAlign="center" horizontalAlign="center">
        <Text variant="xxLarge" className={styles.text}>{languageContext.messages.common.pageNotFound}</Text>
    </Stack>
};

export default GlobalNotFound;
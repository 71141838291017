import React from "react";
import { ILanguage, IMessages } from "../models/translations";

export interface LanguageContextProps {
  messages: IMessages;
  currentLanguage: ILanguage;
  availableLanguages: () => ILanguage[];
  setLanguage: (languageCode: string) => void;
}

export const defaultLanguageContext: LanguageContextProps = {
  messages: {} as IMessages,
  currentLanguage: {} as ILanguage,
  availableLanguages: () => [],
  setLanguage: (languageCode: string) => null
};

export const LanguageContext = React.createContext<LanguageContextProps>(
  defaultLanguageContext
);
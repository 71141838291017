import React, { useContext } from "react";
import { QueryParamsContext } from "../../common/contexts";
import { AppContents, AppFooter, AppHeader } from ".";
import { Stack } from "@fluentui/react";

export interface PageDefaultLayoutProps {
	hideHeader?: boolean;
	hideBodyPadding?: boolean;
	hideFooter?: boolean;
}

export const PageDefaultLayout: React.FC<PageDefaultLayoutProps> = props => {

	const queryParamsContext = useContext(QueryParamsContext);

	const renderHeader = () => {
		if (props.hideHeader) {
			return null;
		}
		if (!queryParamsContext.isEmbedded) {
			return <AppHeader />;
		}

		return null;
  };

	const renderContent = () => {
		if (props.hideBodyPadding) {
			return props.children;
		}

		return (
			<AppContents>{props.children}</AppContents>
		);
	};

	const renderFooter = () => {
		if (!queryParamsContext.isEmbedded) {
			return <AppFooter />;
		}

		return null;
	};

	return (
		<Stack verticalAlign="space-between" styles={{ root: { minHeight: "100vh" } }}>
			<Stack.Item shrink>
				{renderHeader()}
				</Stack.Item>
			<Stack.Item grow disableShrink>
				{renderContent()}
			</Stack.Item>
			{!props.hideFooter && <Stack.Item shrink>
				{renderFooter()}
			</Stack.Item>}
		</Stack>
	);
};

import { IStackTokens, Stack } from "@fluentui/react";
import { useContext } from "react";
import { Button } from "../common/components";
import { LanguageContext } from "../common/contexts";
import styles from "./meetingDataItem.module.scss";


export interface MeetingDataItemProps {
  meetingDataNode: () => JSX.Element;
  onActionButtonClick: () => void;
  isActionButtonDisabled: boolean;
}

const MeetingDataItem: React.FC<MeetingDataItemProps> = (props) => {
  
  const languageContext = useContext(LanguageContext);

  return (
    <Stack tokens={{childrenGap: 20} } wrap horizontalAlign="space-between" verticalAlign="center" className={styles.container} horizontal>
      {props.meetingDataNode()}
      <Button
        text={languageContext.messages.meetingDetailsForm.meetingDataItem.change}
        disabled={props.isActionButtonDisabled}
        onClick={props.onActionButtonClick}
        additionalClassName={styles.actionButton}
      ></Button>
    </Stack>
  );
};

export default MeetingDataItem;
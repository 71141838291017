import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import App from './modules/App';
import { Stores } from "./modules/common/stores";
import { ErrorPage, BookMeetingPage, NotFoundPage, MeetingPage, MeetingDetailsFormPage, AvailabilityCalendarPage, ScheduleMeetingStartingWithDaySelectionPage, LiveMeetingPage } from "./modules/pages";
import { ApplicationInsights } from "./modules/application-insights";
import { routes } from './constants';
import { PageDefaultLayout } from './modules/pages/layout';

const renderDefault = (hideHeader?: boolean, hideBodyPadding?: boolean, hideFooter?: boolean) => (
	<Stores>
		<App>
			<PageDefaultLayout hideHeader={hideHeader} hideBodyPadding={hideBodyPadding} hideFooter={hideFooter}>
				<Outlet />
			</PageDefaultLayout>
		</App>
	</Stores>
);

ReactDOM.render(
	<StrictMode>
		<ApplicationInsights>
			<BrowserRouter>
				<Routes>
					<Route path={routes.meetingLive} element={renderDefault(true, true, true)}>
						<Route path="" element={<LiveMeetingPage/>} />
					</Route>
					<Route path="/" element={renderDefault()}>
						<Route path="" element={<BookMeetingPage />} />
						<Route path={routes.meetingDetailsPage} element={<MeetingDetailsFormPage />} />
						<Route path={routes.employeeSchedule} element={<AvailabilityCalendarPage />} />
						<Route path={routes.scheduleMeetingStartingWithDay} element={<ScheduleMeetingStartingWithDaySelectionPage />} />
						<Route path={routes.meeting} element={ <MeetingPage /> } />
						<Route path="error" element={<ErrorPage />} />
						<Route path="*" element={<NotFoundPage />} />
					</Route>
				</Routes>
		</BrowserRouter>
		</ApplicationInsights>
	</StrictMode>,
	document.getElementById('root')
);
